import React from "react";
import PropTypes from "prop-types";

import Button from "wonderbly-components/lib/Button";

const SubmitButton = (props) => {
  return (
    <div className="form-field-submit">
      <Button id={props.id} type="submit" disabled={props.formSubmitting}>
        {props.label}
      </Button>
    </div>
  );
};

SubmitButton.propTypes = {
  id: PropTypes.string.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default SubmitButton;
