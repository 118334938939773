import React from "react";
import PropTypes from "prop-types";

const updateCheckboxRadioValues = (props, field, newValue) => () => {
  let value = "";
  const values = !props.values[field.inputName]
    ? []
    : props.values[field.inputName].split(",");

  if (values.includes(newValue)) {
    value = values.filter((value) => value !== newValue).join();
  } else {
    value = values.concat(newValue).join();
  }

  props.setFieldValue(
    field.inputName,
    field.inputType === "radio" ? newValue : value
  );
};

// prettier-ignore
const Fields = props => {
  return props.items.map((field, idx) => {
    switch (field.inputType) {
      case 'hidden':
        return (
          <input
            key={`field-${idx}`} 
            type={field.inputType}
            id={field.inputId}
            name={field.inputName}
            value={field.value}
          />
        );
      case 'radio':
      case 'checkbox':
        const options = field.options.split(',')
          .map(option => {
            const [key, value] = option.split(':');
            return {
              key,
              value,
            };
          });
        return (
          <div key={`field-${idx}`} className={`form-field ${field.inputType}`}>
            {field.label && (
              <label htmlFor={field.inputId}>{field.label}</label>
            )}
            <div>
              {options.map(option => (
                <div key={`${field.inputName}_${option.key}`}>
                  <input
                    type={field.inputType}
                    id={`${field.inputName}_${option.key}`}
                    name={field.inputName}
                    value={option.key}
                    onChange={updateCheckboxRadioValues(props, field, option.key)}
                    onBlur={props.onHandleBlur}
                  />
                  <label htmlFor={`${field.inputName}_${option.key}`}>
                    {option.value}
                  </label>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return (
          <div key={`field-${idx}`} className={`form-field ${field.inputType}`}>
            {field.label && (
              <label htmlFor={field.inputId}>{field.label}</label>
            )}
            <input
              type={field.inputType}
              id={field.inputId}
              name={field.inputName}
              placeholder={field.inputPlaceholder}
              onChange={props.onHandleChange}
              onBlur={props.onHandleBlur}
              value={props.values[field.inputName]}
            />
          </div>
        );
    }
  });
};

Fields.propTypes = {
  items: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
};

export default Fields;
