import React from "react";
import PropTypes from "prop-types";

import Errors from "./Errors";
import Fields from "./Fields";
import SubmitButton from "./SubmitButton";

const InnerForm = (props) => (
  <div>
    <Errors errors={props.errors} />
    <form id={props.formId} onSubmit={props.handleSubmit}>
      <Fields
        items={props.fields}
        onHandleChange={props.handleChange}
        setFieldValue={props.setFieldValue}
        onHandleBlur={props.handleBlur}
        values={props.values}
      />
      <SubmitButton
        id={props.submit.inputId}
        label={props.submit.label}
        formSubmitting={props.isSubmitting}
      />
    </form>
  </div>
);

InnerForm.propTypes = {
  formId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  submit: PropTypes.shape({
    inputId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

export default InnerForm;
