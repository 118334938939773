import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { string, object } from "yup";
import SectionHeading from "src/client/js/view/components/SectionHeading";
import InnerForm from "./InnerForm";
import cx from "classnames";

import "./Form.scss";

const getInitialValues = (fields) => {
  let values = {};

  const fieldValues = (field) => (values[field.inputName] = field.value || "");

  fields.map(fieldValues);

  return values;
};

const filterItems = (items) =>
  items.reduce((acc, field) => {
    if (field.inputType === "submit") {
      acc.submit = field;
      return acc;
    }

    const fields = acc.fields || [];
    fields.push(field);
    acc.fields = fields;

    return acc;
  }, {});

const getValidationSchema = (fields) => {
  let schema = {};

  const removeNonRequiredFields = (field) =>
    field.inputType === "hidden" ||
    !field.required ||
    field.required !== "false";

  const errorMessages = (field) => {
    schema[field.inputName] = string().required(
      field.errorMessage || "You must fill this out before submitting."
    );
  };

  fields.filter(removeNonRequiredFields).map(errorMessages);

  return object().shape(schema);
};

const Form = (props) => {
  const { fields, submit } = filterItems(props.items);
  return (
    <section className={cx("form", { [props.formId]: props.formId })}>
      <div className="container-guttered">
        <SectionHeading richText={props.title} />
        {props.subTitle && <p className="form__subtitle">{props.subTitle}</p>}
        <div
          className={cx("form-wrapper", {
            hidden: !props.formVisible,
          })}
        >
          <Formik
            onSubmit={props.handleSubmitForm}
            initialValues={getInitialValues(fields)}
            validationSchema={getValidationSchema(fields)}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {(formProps) => (
              <InnerForm
                {...formProps}
                fields={fields}
                submit={submit}
                formId={props.formId}
              />
            )}
          </Formik>
        </div>
        {!props.formVisible && props.formSuccess && (
          <div className="form-successful">
            <p>{props.formSuccess}</p>
          </div>
        )}
      </div>
    </section>
  );
};

Form.propTypes = {
  title: PropTypes.array,
  subTitle: PropTypes.string,
  formVisible: PropTypes.bool.isRequired,
  formId: PropTypes.string.isRequired,
  formSuccess: PropTypes.string,
  handleSubmitForm: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  updateFormVisibility: PropTypes.func.isRequired,
};

export default Form;
