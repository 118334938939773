import Form from "./Form";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import withState from "recompose/withState";
import withHandlers from "recompose/withHandlers";
import withCountry from "../../hocs/withCountry";

const enhance = compose(
  mapProps((props) => ({
    ...props.primary,
    items: props.items,
  })),
  withState("formVisible", "updateFormVisibility", true),
  withCountry,
  withHandlers({
    handleSubmitForm: (props) => (values, actions) => {
      props
        .onSubmit(values, props.country)
        .then(() => {
          actions.resetForm();
          props.updateFormVisibility(false);
          setTimeout(() => {
            props.updateFormVisibility(true);
          }, 10000);
        })
        .catch(() => {
          actions.setSubmitting(false);
          actions.setErrors({
            form: props.formError,
          });
        });
    },
  })
);

export default enhance(Form);
