import Form from "../Form";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import withGlobalContent from "../../hocs/withGlobalContent";
import * as subscriptionsFacade from "../../../facades/eagle/subscriptions";

import "./Newsletter.scss";

const enhance = compose(
  withGlobalContent,
  mapProps((props) => ({
    primary: {
      // Documents can have global, local or null titleSource value due to prismic quirk
      title:
        props.primary.titleSource === "LocalTitle"
          ? props.primary.title
          : props.globalContent.general.newsletterSignUpTitle,
      subTitle: props.globalContent.general.newsletterSignUpSubtitle,
      formId: "sign-up-newsletter",
      formError: props.globalContent.error.email.invalid,
      formSuccess: props.globalContent.success.newsletter,
      onSubmit: subscriptionsFacade.newsletter,
    },
    items: [
      {
        inputType: "hidden",
        inputId: "signup_source",
        inputName: "signup_source",
        value: props.primary.signupSource || "site:partial",
      },
      {
        inputType: "email",
        inputId: "email",
        inputName: "email",
        inputPlaceholder: props.primary.emailLabel,
        required: true,
        errorMessage: props.primary.invalidEmail,
      },
      {
        inputType: "submit",
        inputId: "submit",
        inputName: "submit",
        label: props.primary.submitLabel,
      },
    ],
  }))
);

export default enhance(Form);
