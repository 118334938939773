import React from "react";
import PropTypes from "prop-types";

const Errors = (props) => {
  const errors = Object.keys(props.errors);
  if (errors.length === 0) {
    return null;
  }
  return (
    <div className="errors">
      {errors.map((error) => (
        <p key={error}>{props.errors[error]}</p>
      ))}
    </div>
  );
};

Errors.propTypes = {
  errors: PropTypes.object,
};

export default Errors;
